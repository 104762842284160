import AccountService, { UnauthorizedError } from "../services/AccountService";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import jwtDecode from "jwt-decode";

export namespace LoginStore {
  export interface IState {
    loginState:
      | "LOGGED_OUT"
      | "SENDING_CHALLENGE"
      | "AWAITING_CHALLENGE"
      | "VALIDATING_CHALLENGE"
      | "LOGGED_IN"
      | "LOGIN_FAILED";
    loginError: string;
    accessToken: string;
    refreshToken: string;
    expiresAt: number;
    challengeEmail: string | null;
  }

  export enum Actions {
    SetSession = "LOGIN_SET_SESSION",
    SetLoginState = "LOGIN_SET_STATE",
    SetChallengeEmail = "LOGIN_SET_CHALLENGE_EMAIL",
  }

  interface ISetSession {
    type: Actions.SetSession;
    refreshToken: string;
    accessToken: string;
    expiresAt: number;
  }

  interface ISetLoginState {
    type: Actions.SetLoginState;
    loginState:
      | "LOGGED_OUT"
      | "SENDING_CHALLENGE"
      | "AWAITING_CHALLENGE"
      | "VALIDATING_CHALLENGE"
      | "LOGGED_IN"
      | "LOGIN_FAILED";
    loginError: string | null;
  }

  interface ISetChallengeEmail {
    type: Actions.SetChallengeEmail;
    email: string | null;
  }

  type KnownAction = ISetSession | ISetLoginState | ISetChallengeEmail;

  export const actionCreators = {
    init: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const refreshToken = localStorage.getItem("refreshToken");
      const accessToken = localStorage.getItem("accessToken");
      if (refreshToken) {
        const expiresAt =
          (accessToken && jwtDecode<{ exp: number }>(accessToken).exp) || 0;

        dispatch({
          type: Actions.SetSession,
          refreshToken: refreshToken,
          accessToken: accessToken,
          expiresAt: expiresAt,
        });

        dispatch({
          type: Actions.SetLoginState,
          loginState: "LOGGED_IN",
          loginError: null,
        });
      }
    },
    startLogin:
      (email: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetLoginState,
          loginState: "SENDING_CHALLENGE",
          loginError: null,
        });
        try {
          await AccountService.sendAuthChallenge(email);
          dispatch({ type: Actions.SetChallengeEmail, email });
          dispatch({
            type: Actions.SetLoginState,
            loginState: "AWAITING_CHALLENGE",
            loginError: null,
          });
        } catch (error: any) {
          dispatch({
            type: Actions.SetLoginState,
            loginState: "LOGIN_FAILED",
            loginError: error.message,
          });
        }
      },

    validateChallenge:
      (challenge: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const email = getState().login.challengeEmail;
        if (!email) {
          dispatch({
            type: Actions.SetLoginState,
            loginState: "LOGIN_FAILED",
            loginError: "No email provided",
          });
          return;
        }

        dispatch({
          type: Actions.SetLoginState,
          loginState: "VALIDATING_CHALLENGE",
          loginError: null,
        });
        try {
          const result = await AccountService.validateAuthChallenge(
            email,
            challenge
          );
          localStorage.setItem("refreshToken", result.refreshToken);
          localStorage.setItem("accessToken", result.accessToken);
          const expiresAt = jwtDecode<{ exp: number }>(result.accessToken).exp;

          dispatch({
            type: Actions.SetSession,
            refreshToken: result.refreshToken,
            accessToken: result.accessToken,
            expiresAt: expiresAt,
          });

          dispatch({ type: Actions.SetChallengeEmail, email: null });
          dispatch({
            type: Actions.SetLoginState,
            loginState: "LOGGED_IN",
            loginError: null,
          });
        } catch (error: any) {
          dispatch({
            type: Actions.SetLoginState,
            loginState: "LOGIN_FAILED",
            loginError: error.message,
          });
        }
      },
    logout: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({
        type: Actions.SetSession,
        refreshToken: null,
        accessToken: null,
        expiresAt: null,
      });
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");

      dispatch({
        type: Actions.SetLoginState,
        loginState: "LOGGED_OUT",
        loginError: null,
      });
    },
    refreshToken:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const refreshToken = getState().login.refreshToken;
        if (refreshToken) {
          try {
            const result = await AccountService.refreshToken(refreshToken);
            localStorage.setItem("refreshToken", result.refreshToken);
            localStorage.setItem("accessToken", result.accessToken);
            const expiresAt = jwtDecode<{ exp: number }>(
              result.accessToken
            ).exp;

            dispatch({
              type: Actions.SetSession,
              refreshToken: result.refreshToken,
              accessToken: result.accessToken,
              expiresAt: expiresAt,
            });
          } catch (error: any) {
            if (error instanceof UnauthorizedError) {
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("accessToken");
              document.location.reload();
            }

            dispatch({
              type: Actions.SetLoginState,
              loginState: "LOGIN_FAILED",
              loginError: error.message,
            });
          }
        }
      },
  };

  interface IBoundActions {
    init: () => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    refreshToken: () => Promise<void>;
  }

  export const boundActions: IBoundActions = {} as IBoundActions;

  export const initBoundActions = (dispatch, getState) => {
    Object.keys(actionCreators).forEach((action) => {
      boundActions[action] = (...args: any[]) =>
        actionCreators[action](...args)(dispatch, getState);
    });
  };

  const initialState: IState = {
    loginState: "LOGGED_OUT",
    loginError: null,
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    challengeEmail: null,
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.SetSession:
        return {
          ...currentState,
          refreshToken: action.refreshToken,
          accessToken: action.accessToken,
          expiresAt: action.expiresAt,
        };
      case Actions.SetLoginState:
        return {
          ...currentState,
          loginState: action.loginState,
          loginError: action.loginError,
        };
      case Actions.SetChallengeEmail:
        return {
          ...currentState,
          challengeEmail: action.email,
        };
      default:
        return currentState || initialState;
    }
  };
}
